<template>
  <div class="sign">
    <p class="title">Sua empresa teve condições aprovadas pelo comitê de crédito, para da continuidade ao processo de empréstimo você precisa visualizar e assinar as condições aprovadas.</p>
    <p @click="submit" class="subtitle">Deseja ser redirecionado para a página de assinatura?, Clique aqui.</p>
  </div>
</template>

<script>
export default {
  methods: {
    async submit () {
      this.$router.push('/tomador/aceitar-condicoes')
    }
  }
}
</script>

<style lang="sass" scoped>

.sign
  @apply w-full border-2 rounded-lg py-3 px-4 mb-5
  border-color: #CCBEA0
  background: #FFFAF3
  color: #7A4D05
  @screen lg
    @apply flex-row items-end

.title
  @apply text-sm font-bold
  @screen lg
    @apply text-base mr-2

.subtitle
  @apply text-xs cursor-pointer
  @screen lg
    @apply text-sm
  &:hover
    @apply underline

</style>
