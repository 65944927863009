<template>
  <div>
    <status-subscriptions-modal v-if="showModal" @closeModal="showModal = !showModal"/>
    <div class="sign">
      <p class="title">Alguns sócios precisam assinar as condições aprovadas de empréstimo.</p>
      <p @click="showModal = !showModal" class="subtitle">Deseja ver o status das assinaturas?, Clique aqui.</p>
    </div>
  </div>
</template>

<script>
import StatusSubscriptionsModal from '@/components/Business/Modal/StatusSubscriptionsModal'
export default {
  components: { StatusSubscriptionsModal },
  data () {
    return {
      showModal: false
    }
  }
}
</script>

<style lang="sass" scoped>

.sign
  @apply w-full border-2 rounded-lg py-3 px-4 mb-5
  border-color: #CCBEA0
  background: #FFFAF3
  color: #7A4D05
  @screen lg
    @apply flex-row items-end

.title
  @apply text-sm font-bold
  @screen lg
    @apply text-base mr-2

.subtitle
  @apply text-xs cursor-pointer
  @screen lg
    @apply text-sm
  &:hover
    @apply underline

</style>
