<template>
  <div class="main">
    <div class="flex flex-col justify-center bg-white shadow-md">
      <div class="container flex justify-center mb-4 h-16">
        <nav-bar />
      </div>
    </div>

    <div class="container">
      <div class="mt-6">
        <email-not-confirmed-alert v-if="!$store.state.business.emailConfirmed" class="mb-6" req="tomador" />
        <documents-missing
          v-if="$store.state.business && $store.state.business.step === 3 && $store.state.business.sentAnyDoc && $route.path === '/tomador/perfil/documentos'">
        </documents-missing>
        <documents-in-review
          v-if="$store.state.business && $store.state.business.step === 4 && $store.state.business.docsInReview && $route.path === '/tomador/perfil/documentos'">
        </documents-in-review>
        <user-need-sign v-if="userNeedSign && !($route.path === '/tomador/aceitar-condicoes')"></user-need-sign>
        <status-subscriptions v-if="statusSubscriptions && !($route.path === '/tomador/aceitar-condicoes')">
        </status-subscriptions>
      </div>

      <router-view @updateBusiness="account"></router-view>

      <Footer class="mt-auto" :dashboard="true" />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Business/NavBar/NavBar'
import Footer from '@/components/Footer/Footer'
import EmailNotConfirmedAlert from '@/components/Alerts/EmailNotConfirmedAlert'
import DocumentsMissing from '@/components/Alerts/DocumentsMissing'
import DocumentsInReview from '@/components/Alerts/DocumentsInReview'
import UserNeedSign from '@/components/Alerts/UserNeedSign'

import Dashboard from '@/services/Business/Dashboard'
import StatusManager from '@/services/Business/StatusManager'

import ApprovedCondition from '@/services/Commons/ApprovedCondition'

import StatusSubscriptions from '@/components/Alerts/StatusSubscriptions'

export default {
  name: 'dashboard',
  components: {
    NavBar,
    Footer,
    EmailNotConfirmedAlert,
    DocumentsMissing,
    DocumentsInReview,
    UserNeedSign,
    StatusSubscriptions
  },
  data() {
    return {
      userNeedSign: false,
      statusSubscriptions: false
    }
  },
  methods: {
    clearJivoInterval() {
      clearInterval(this.jivoInterval)
    },
    async account() {
      //await StatusManager.refresh();

      try {
        const response = (await ApprovedCondition.userNeedSign()).data.data
        response ? (this.userNeedSign = true) : (this.userNeedSign = false)
      } catch (error) {
        console.log(error)
      }
      try {
        const subscriptions = (await ApprovedCondition.signStatus()).data.data
        if (
          subscriptions.filter(item => item.dataAssinatura === null).length > 0
        ) {
          this.statusSubscriptions = true
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.account()
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply w-full min-h-screen max-h-full
  background: #F5F5F5

.content
  @apply w-full flex flex-col items-center bg-white p-6 mb-8 border shadow rounded-lg

</style>
