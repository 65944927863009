<template>
  <div class="email_alert">
    <p class="title">Sua conta não está confirmada.</p>
    <p @click="submit" class="subtitle">Caso não tenha recebido o e-mail de confirmação, clique aqui.</p>
  </div>
</template>

<script>
import ConfirmAccount from '@/services/Commons/ConfirmAccount'
export default {
  props: ['req'],
  methods: {
    async submit () {
      try {
        await ConfirmAccount.requestConfirmEmailAgain(this.req)
        this.$store.dispatch('notification', { type: 'success', message: 'Email enviado com sucesso, verifique sua caixa de email.' })
      } catch (error) {
        console.error(error)
        this.$store.dispatch('notification', { type: 'error', message: 'Falha ao enviar email' })
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.email_alert
  @apply w-full bg-white border-2 border-red-600 text-red-600 rounded-lg py-3 px-4
  background-color: rgba(229, 62, 62, 0.1)
  @screen lg
    @apply flex-row items-end

.title
  @apply text-sm font-bold
  @screen lg
    @apply text-base mr-2

.subtitle
  @apply text-xs cursor-pointer
  @screen lg
    @apply text-sm
  &:hover
    @apply underline

</style>
