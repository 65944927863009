import { render, staticRenderFns } from "./DocumentsMissing.vue?vue&type=template&id=03afa442&scoped=true&"
var script = {}
import style0 from "./DocumentsMissing.vue?vue&type=style&index=0&id=03afa442&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03afa442",
  null
  
)

export default component.exports