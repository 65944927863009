<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Status das Assinaturas</p>
        <div class="w-full overflow-x-auto lg:overflow-x-hidden">
          <table>
            <thead>
              <tr>
                <th>
                  Nome
                </th>
                <th>
                  Status Assinatura
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(socio, index) of socios" :key="index">
                <td class="px-4">
                  {{socio.nomeSocio}}
                </td>
                <td class="px-4">
                  {{socio.aceitouCondicoes ? 'Assinou' : !socio.aceitouCondicoes && socio.dataAssinatura ? 'Recusou condições' : 'Falta assinar'}}
                </td>
              </tr>
              <tr v-if="!socios.length" class="text-center text-sm">
                <td class="py-2">
                  Não há sócios na lista
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ApprovedCondition from '@/services/Commons/ApprovedCondition'

export default {
  name: 'status-subscriptions',
  data () {
    return {
      socios: []
    }
  },
  async mounted () {
    try {
      this.socios = (await ApprovedCondition.signStatus()).data.data
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full overflow-y-auto bg-white z-50 flex flex-col rounded-lg p-4
  min-height: 15rem
  @screen lg
    @apply w-3/4

.title
  @apply text-secondary font-bold mb-4 text-center mt-2
  @screen lg
    @apply text-left text-lg

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

table
  @apply w-full border-none mt-4
  th
    @apply py-1 opacity-75 font-bold text-sm text-left bg-gray-200 px-6
  tr
    @apply py-2 border-t border-b border-gray-200
    &:hover
      @apply bg-gray-100
  td
    @apply py-1 opacity-75 text-sm self-start

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>
